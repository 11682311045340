define("discourse/plugins/discourse-snippet/initializers/snippet", ["exports", "discourse/lib/plugin-api", "discourse/controllers/composer"], function (_exports, _pluginApi, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeSnippetMenu(api) {
    api.addComposerToolbarPopupMenuOption({
      action: toolbarEvent => {
        toolbarEvent.applySurround('[snippet]\n', '\n[/snippet]', 'snippet_text', {
          multiline: false
        });
      },
      icon: 'sticky-note',
      label: 'snippet.title'
    });
    api.addComposerToolbarPopupMenuOption({
      action: toolbarEvent => {
        toolbarEvent.applySurround('[space]\n', '\n[/space]', 'space_text', {
          multiline: false
        });
      },
      icon: 'database',
      label: 'space.title'
    });
    api.addComposerToolbarPopupMenuOption({
      action: toolbarEvent => {
        toolbarEvent.applySurround('[bundle]\n', '\n[/bundle]', 'bundle_text', {
          multiline: false
        });
      },
      icon: 'folder',
      label: 'bundle.title'
    });
  }
  var _default = _exports.default = {
    name: 'apply-snippets',
    initialize(container) {
      const siteSettings = container.lookup('site-settings:main');
      if (siteSettings.snippet_enabled) {
        (0, _pluginApi.withPluginApi)('1.15.0', initializeSnippetMenu);
      }
    }
  };
});