define("discourse/plugins/discourse-snippet/lib/discourse-markdown/snippets", ["exports", "pretty-text/emoji"], function (_exports, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  // import the regex variable used by discourse's emoji plugin to perform emoji escape

  function setup(helper) {
    helper.allowList(['div.discourse-snippet', 'div.discourse-space', 'div.discourse-bundle']);
    helper.registerOptions((opts, siteSettings) => {
      opts.features['snippet'] = !!siteSettings.snippet_enabled;
      opts.features['space'] = !!siteSettings.snippet_enabled;
      opts.features['bundle'] = !!siteSettings.snippet_enabled;
    });
    helper.registerPlugin(md => {
      md.block.bbcode.ruler.push('snippet', {
        tag: 'snippet',
        replace: function (state, tagInfo, content) {
          const token = state.push("wrap_open", "div", 1);
          token.attrs = [['class', 'discourse-snippet'], ['data-name', tagInfo.attrs['name'] || 'Text Blaze Snippet']];
          if (tagInfo.attrs['shortcut']) {
            token.attrs.push(['data-shortcut', tagInfo.attrs['shortcut']]);
          }
          if (tagInfo.attrs['quickentry']) {
            token.attrs.push(['data-quickentry', tagInfo.attrs['quickentry']]);
          }
          if (tagInfo.attrs['hidePreview']) {
            token.attrs.push(['data-hide-preview', tagInfo.attrs['hidePreview']]);
          }
          const contentEncoded = content.replace(new RegExp(_emoji.emojiReplacementRegex, "g"), match => {
            return `{{^^${encodeURIComponent(match)}^^}}`;
          });
          md.block.parse(contentEncoded, state.md, state.env, state.tokens);
          state.push("wrap_close", "div", -1);
          return true;
        }
      });
      md.block.bbcode.ruler.push('bundle', {
        tag: 'bundle',
        wrap: function (token, tagInfo) {
          token.attrs = [['class', 'discourse-bundle'], ['data-name', tagInfo.attrs['name'] || 'Text Blaze Bundle'], ['data-hide-preview', tagInfo.attrs['hidePreview'] || 'false']];
          if (tagInfo.attrs['id']) {
            token.attrs.push(['data-id', tagInfo.attrs['id']]);
          }
          return true;
        }
      });
      md.block.bbcode.ruler.push('space', {
        tag: 'space',
        replace: function (state, tagInfo, content) {
          let token;
          token = state.push("wrap_open", "div", 1);
          token.attrs = [['class', 'discourse-space'], ['data-code', JSON.stringify({
            code: content
          })]];
          if (tagInfo.attrs['name']) {
            token.attrs.push(['data-name', tagInfo.attrs['name']]);
          }
          if (tagInfo.attrs['icon']) {
            token.attrs.push(['data-icon', tagInfo.attrs['icon']]);
          }
          state.push("wrap_close", "div", -1);
          return true;
        }
      });
    });
  }
});